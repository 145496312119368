export const copyRightYearOldTenantReplacer = ["1", "7", "28", "29", "18"];
export const multiRowAllowSlickReplacer = ["40", "46"];
export const menuToggle = [
  "74",
  "62",
  "77",
  "72",
  "64",
  "69",
  "46",
  "66",
  "70",
  "75",
  "82",
  "83",
  "59",
  "47",
];
export const menuToggleForSpecialTenant = ["44"];
export const menuToggleClose = ["47"];
export const classNamesForTenant = ["47"];
export const mobileAccordionScrollTopZero = [
  "28",
  "69",
  "25",
  "27",
  "84",
  "45",
  "46",
  "37",
  "17",
  "59",
  "31",
  "83",
  "19",
  "67",
  "68",
  "75",
  "76",
  "80",
  "70",
  "83",
  "82",
  "1",
  "18",
  "12",
  "74",
  "86",
  "87",
  "65",
  "89",
  "91",
  "92",
  "94",
  "93",
  "96",
  "95",
];
export const contactPageHideParentIfBioNotPresent = ["38"];
export const footerAddressReplacerOrHideInnerChild = ["37"];
export const changeClassForCurrentFund = ["31"];
// export const customYearRendering = ["58"];
export const learnMoreClass = ["learn-more-wrapper", "pb-learn-more-text"];
export const ccTitleForTenant = ["7", "8", "17", "1", "16"];
export const hideSelectFundInMobile = [
  "19",
  "76",
  "70",
  "84",
  "83",
  "39",
  "69",
  "72",
  "74",
  "77",
  "28",
  "26",
  "50",
  "63",
  "64",
  "82",
  "47",
  "46",
  "45",
  "41",
  "42",
  "58",
  "29",
  "31",
  "33",
  "34",
  "35",
  "36",
  "59",
  "51",
  "55",
  "57",
  "38",
  "37",
  "81",
  "16",
  "18",
  "1",
  "8",
  "48",
  "67",
  "66",
  "80",
  "27",
  "53",
  "79",
  "52",
  "78",
  "86",
  "65",
];
export const changePlusMinusIconPOC = ["52", "53", "78", "79"];
export const renderSocialIconAsText = [72];
